<template>
  <div class="face_wrap">
    <div class="center_inner">
      <img class="face-bg" src="@/assets/image/face-bg.png" alt="">
      <h4>{{loading ? '正在' : ''}}接入人脸识别页面</h4>
      <div class="video_box">
        <img class="scangif" v-show="isnotbtn && !isExtract" src="@/assets/image/face.gif" alt="">
        <a id="downLoadLink" style="display: none;"></a>
        <video
          v-show="isnotbtn"
          ref="video"
          class="videoClass"
          id="myVideo" muted playsinline
        ></video>
        <canvas  class="videoClass" id="myCanvas" />
        <canvas ref="canvas" width="400" height="300"  v-show="false" />
        <!-- 截图展示图片 -->
        <img  v-show="false" ref="screenshot">
        <!-- <div class="pan-thumb" v-show="isnotbtn"> 
          <div class="center"></div>
        </div> -->
        <!-- 未识别到摄像头时图片替换 -->
        <img v-show="!isnotbtn || isExtract" class="errorImg" src="@/assets/image/headImg2.png" alt="">
      </div>
      <div class="font">
        <p class="p1" v-show="!loading && !isnotbtn">摄像头打开失败</p>
        <p class="p1" v-show="isnotbtn && !isExtract">{{isFace ? '正在提取人脸信息，请勿移动' : '请正视摄像头，露出面部'}}</p>
        <p class="p1" v-show="isExtract">面部信息提取完毕，正在进行校验</p>
        <p class="p2">本次操作遵守国家法律法规，采集的信息仅用于此次考试</p>
      </div>
    </div>
    <a-modal :maskClosable="false" v-model="isExamCodeShow" @cancel="cancelModal">
      <div class="modal-box">
        <h3>{{isnotbtn?'人脸识别不通过':'摄像头打开失败'}}</h3>
        <div class="means-item">
          <span>方式一</span>
          <div class="content-inner">
            <div class="head-top">
              <img src="@/assets/image/icon7.png" alt="">
              <div class="side-r">
                <span>调整摄像头，再次识别</span>
                <p>请把摄像头对准您的人脸部位，或检测您的摄像头是否开启权限，是否连接。</p>
              </div>
            </div>
            <a-button type="primary" class="btn" @click="callCamera">再试一次</a-button>
          </div>
        </div>
        <div class="means-item">
          <span>方式二</span>
          <div class="content-inner two-inner">
            <div class="head-top">
              <img src="@/assets/image/icon7.png" alt="">
              <div class="side-r">
                <span>人工信息识别</span>
                <p>请您拨打工作人员电话，进行人工审核。工作人员审核成功后会给您发送验证码，在进入考试前输入考试码即可进行考试。</p>
              </div>
            </div>
            <div class="phone-bottom">
              <p>工作人员联系方式：</p>
              <div class="mobile">
                <span v-for="item in linkData" :key="item.tellId">{{item.name}}：{{item.mobile}}</span>
              </div>
              <div class="input-code">
                <span class="codeText">考试码：</span>
                <a-input class="input" placeholder="考试码" :maxLength=6 v-model="examCode" />
              </div>
            </div>
            <a-button type="primary" class="btn" :loading="loadBtn" @click="FunCheckCode">进入考试</a-button>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import * as faceapi from "face-api.js";
import { FunError } from '@/utils/fun.js'
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      isnotbtn: false,
      loading: true,
      isContrastLoading: false,  // 是否处于比对
      faceCount: 0,  // 比对的次数
      isExamCodeShow: false,
      demo: '',
      nets: "tinyFaceDetector", // 模型
      withBoxes: false,  // 框or轮廓
      options: null, // 模型参数
      detectFace: "detectSingleFace", // 单人脸检测     多人脸 detectAllFaces  
      videoEl: null,  // 视频dom
      canvasEl: null,  // 画布dom
      timeout: 0,
      isFace:false,  // 是否检测到人脸

      isOnce:true,
      isExtract:false,  // 是否已完成提取人脸信息
      faceForm:{
        imageUrl: this.$store.state.userInfo.certifimobile,
        videoUrl:'https://devpcloud.cn/dev/management/20220510/2920f227efa14146a3a651d1f269cc72.mp4',
        validateData: '' //动作顺序
      },
      linkData:[],
      examCode:'',  // 考试码
      loadBtn:false
    }
  },
  // 事件处理器
  methods: {
    // 校验考试码
    FunCheckCode(){
      let obj = this.$store.state.userInfo
      if(!this.examCode){
        this.$message.warning('请输入考试码');
        return
      }
      this.loadBtn = true
      this.$ajax({
        method: 'get',
        url:"/exam/system/examcode",
        params:{
          examCode: this.examCode,
          signupuserid: obj.signupuserid
        }
      }).then(res => {
        this.loadBtn = false
        if (res.code == 200 && res.success) {
          if(res.data){

            // type 进入方式 1.人脸识别 2.考试码
            this.$ajax({
              method: 'put',
              url:"/exam/system/edit?entyMode=2" + '&examineeId=' + obj.examineeId,
            })

            obj.isSubmit = false  // 是否提交试卷
            this.$store.commit("updateUserInfo", obj);  // 更新个人信息
            this.$router.push({ path: "/ExamView/ExamInfo" });
          } else {
            this.examCode = ''
            this.$message.error('考试码不正确');
          }
        }
      })
    },
    cancelModal(){
      // this.$router.go(-1)
      this.$router.push({ path: "/ExamView" });
    },
    // 调用摄像头
    callCamera() {
      this.faceCount = 0
      // 页面停留1分钟如果未反应将弹错误窗
      setTimeout(()=>{
        clearTimeout(this.timer);  // 清除对比
        this.closeCamera()  // 关闭摄像头
        this.isExamCodeShow = true   // 打开错误弹窗

        let obj = this.$store.state.userInfo
        obj.isDisable = false
        this.$store.commit("updateUserInfo", obj);  // 更新个人信息

        this.isnotbtn = true;  // 对比失败提醒
      },60000)

      this.loading = true
      this.isnotbtn = false
      this.isExtract = false
      // 旧版本浏览器可能根本不支持mediaDevices，我们首先设置一个空对象
      if (navigator.mediaDevices === undefined) {
        navigator.mediaDevices = {};
      }
      this.isExamCodeShow = false
      // H5调用电脑摄像头API
      navigator.mediaDevices
      .getUserMedia({
        video: true,
      })
      .then((success) => {
        this.loading = false
        this.isnotbtn = true;
        // 摄像头开启成功
        this.$refs["video"].srcObject = success;
        // 实时拍照效果
        this.$refs["video"].play();
        setTimeout(() => {
          // this.fnRun()
        }, 500); // 开始绘画人脸追踪
        // 定时对比
        this.timer = setInterval(()=>{
          // 如果检测到人脸时才会拍照
          // if(this.isFace && !this.isContrastLoading){
            this.screenshot()
          // }
        }, 4000)
      })
      .catch((error) => {
        this.loading = false
        let obj = this.$store.state.userInfo
        obj.isDisable = false
        this.$store.commit("updateUserInfo", obj);  // 更新个人信息
        setTimeout(() => this.isExamCodeShow = true, 1000); // 摄像头打开失败 打开弹窗
      });
    },

    // 关闭摄像头
    closeCamera() {
      if (!this.$refs["video"].srcObject) {
        this.dialogCamera = false;
        return;
      }
      let stream = this.$refs["video"].srcObject;
      let tracks = stream.getTracks();
      tracks.forEach((track) => {
        track.stop();
      });
      this.$refs["video"].srcObject = null;
      this.isnotbtn = false;
    },

    // 截图
    screenshot() {
      // 获取视频
      const video = this.$refs.video;
      // 获取截图的地址
      const screenshot = this.$refs.screenshot;
      // 获取canvas
      const canvas = this.$refs.canvas;
      // 渲染一个2d平面的视图
      const ctx = canvas.getContext("2d");
      // 设置canvas 视图文件地址和大小
      ctx.drawImage(video, 0, 0, 400, 300);
      // 将数据转为base64赋值给img标签的src属性
      screenshot.src = canvas.toDataURL("image/png");
      const imgBase = canvas.toDataURL("image/png")
      this.isContrastLoading = true
      // 图片上传服务器
      this.$uoloadImg({
        file: this.dataURLtoBlob(imgBase),
        progress: (e)=>{
        },
        success: (e)=>{
          // 返回的图片去对比
          this.faceCheck(e)
        }
      })
    },

    // 人脸比对
    faceCheck(photo){
      this.$ajax({
        method: 'get',
        url:"/exam/system/compare-face",
        params:{
          photo1: this.$store.state.userInfo.certifimobile,   // 证件照
          photo2: photo,
          examineeId: this.$store.state.userInfo.examineeId, // 考生id
        }
      }).then(res => {
        this.isContrastLoading = false
        let obj = this.$store.state.userInfo
        // 相似度大于85 > 65即可完成认证
        if(res > 65){
          obj.isDisable = false
          obj.isPassed = true
          obj.entyMode = 3  // 照片对比

          this.$store.commit("updateUserInfo", obj);  // 更新个人信息

          this.$message.success('识别成功');

          clearTimeout(this.timer);  // 清除对比
          this.closeCamera()  // 关闭摄像头
          // this.$router.go(-1)
          this.$router.push({ path: "/ExamView" });
        } else {
          this.funWatchError(9, 2)
          // 如果对比失败  那就记录一次
          this.faceCount = this.faceCount + 1
        }
        // 对比错误三次就弹窗
        if(this.faceCount > 4 ){
          clearTimeout(this.timer);  // 清除对比
          this.closeCamera()  // 关闭摄像头
          this.isExamCodeShow = true   // 打开错误弹窗

          obj.isDisable = false
          this.$store.commit("updateUserInfo", obj);  // 更新个人信息

          this.isnotbtn = true;  // 对比失败提醒
        }
      })
    },

    // base64转bold文件
    dataURLtoBlob(dataurl){
      var arr = dataurl.split(',');
      var mime = arr[0].match(/:(.*?);/)[1];
      var bstr = atob(arr[1]);
      var n = bstr.length;
      var u8arr = new Uint8Array(n);
      while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },

    // 监听异常记录
    funWatchError(type, status){
      if(this.loading) return
      this.$ajax({
        method: "POST",
        url: "/exam/abnor-oper/save?content=" + FunError(type) + '&examineeId=' + this.$store.state.userInfo.examineeId + '&status=' + status,
      })
    },

    // 初始化模型加载
    async fnInit() {
      await faceapi.nets[this.nets].loadFromUri("/models"); // 算法模型
      await faceapi.loadFaceLandmarkModel("/models"); // 轮廓模型
      // 根据模型参数识别调整结果
      this.options = new faceapi.TinyFaceDetectorOptions({
        inputSize: 512, // 160 224 320 416 512 608
        scoreThreshold: 0.5, // 0.1 ~ 0.9
      });
      // 节点属性化
      this.videoEl = document.getElementById("myVideo");
      this.canvasEl = document.getElementById("myCanvas");
    },

    // 节点对象执行递归识别绘制
    async fnRun() {
      if (this.videoEl.paused) return clearTimeout(this.timeout);
      // 识别绘制人脸信息
      const result = await faceapi[this.detectFace](
        this.videoEl,
        this.options
      ).withFaceLandmarks();
      if (result) {  // 检测到人脸
        this.isFace = true
        const dims = faceapi.matchDimensions(this.canvasEl, this.videoEl, true);
        const resizeResult = faceapi.resizeResults(result, dims);
        // this.withBoxes
        //   ? faceapi.draw.drawDetections(this.canvasEl, resizeResult)
        //   : faceapi.draw.drawFaceLandmarks(this.canvasEl, resizeResult);
      } else {
        // 未检测到人脸
        this.isFace= false
        this.canvasEl
          .getContext("2d")
          .clearRect(0, 0, this.canvasEl.width, this.canvasEl.height);
      }
      this.timeout = setTimeout(() => this.fnRun());
    },

    // 获取联系人
    getLinkList(){
      this.$ajax({
        method: 'get',
        url:"/exam/system/tell-list",
        params:{
          configId: this.$store.state.userInfo.configId,
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.linkData = res.data
        }
      })
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    this.callCamera()
    this.getLinkList()
  },
  // 生命周期-实例挂载后调用
  mounted () { 
    this.$nextTick(() => {
      // this.fnInit();
    });
  },
  // 生命周期-实例销毁前调用
  beforeDestroy () {
    // this.videoEl.pause();
    clearTimeout(this.timeout);
    clearTimeout(this.timer);
    this.closeCamera()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () {
  },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.face_wrap{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  .center_inner{
    text-align: center;
    width: 600px;
    height: 600px;
    position: relative;
    .face-bg{
      position: absolute;
      left: 0;
      top: 0;
      width: 600px;
      height: 600px;
      z-index: -1;
    }
    h4{
      margin-top: 100px;
      font-size: 24px;
      font-weight: 500;
      color: #000000;
    }
    .face_box{
      margin: 20px auto;
    }
  }
}
.video_box{
  margin: 95px auto 20px;
  overflow: hidden;
  width: 217px;
  height: 217px;
  position: relative;
  text-align: center;
  .scangif{
    width: 200px;
    margin-top: 11px;
    margin-left: 8px;
  }
  .videoClass {
    transform: rotateY(180deg);
    width: 209px;
    height: 209px;
    border-radius: 50%;
    object-fit: cover;
    position: absolute;
    left: 4px;
    top: 4px;
    z-index: -2;
  }

  /* 视频边框旋转加载边框 */
  .pan-thumb {
    position: absolute;
    left: 0;
    top: 0;
    width:217px;
    height:217px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: linear-gradient(rgba(45, 116, 221, 1),rgba(255, 255, 255, 1));
    animation: rotate 1s infinite linear;
  }
  .pan-thumb:hover {
    transform: rotate(-100deg);
  }
  .pan-thumb>.center{
    position: absolute;
    z-index: 50;
    width: 208px;
    height: 208px;
    border-radius: 50%;
    background-color: #ffffff;
  }
  .errorImg{
    width: 140px;
    margin-top: 40px;
    margin-left: 10px;
  }

  /* 渐变色背景旋转动画 */
  @keyframes rotate {
    0% {
    transform: rotateZ(0);
    }
    100% {
    transform: rotateZ(360deg);
    }
  }
}



.font{
  margin-top: 40px;
  font-weight: 400;
  .p1{
    font-size: 22px;
    color: #333333;
    color: rgba(51,51,51,0.8)

  }
  .p2{
    margin-top: 10px;
    font-size: 14px;
    color: #999999;
  }
}

.modal-box{
  padding: 24px 40px 32px;
  >h3{
    font-weight: 500;
    color: #333333;
    font-size: 18px;
    text-align: center;
  }
  .means-item{
    >span{
      display: inline-block;
      font-weight: 500;
      color: #333333;
      margin-bottom: 5px;
    }
    .content-inner{
      background: #F8F8F8;
      padding: 24px 20px;
      margin-bottom: 24px;
      padding-right: 45px;
      text-align: center;
      .head-top{
        display: flex;
        img{
          width: 18px;
          height: 18px;
        }
        .side-r{
          text-align: left;
          flex: 1;
          margin-left: 8px;
          span{
            color: #333333;
          }
          p{
            font-size: 12px;
            color: #666666;
          }
        }
      }
      .btn{
        margin-top: 32px;
        min-width: 212px;
        height: 43px;
        background: linear-gradient(180deg, #3681F0 0%, #2263C5 100%);
      }
    }
    .two-inner{
      .head-top{
        padding-bottom: 12px;
      }
    }
    .phone-bottom{
      padding-top: 12px;
      font-size: 12px;
      text-align: left;
      margin-left: 25px;
      border-top: 1px solid #E6E7E8;
      color: #333333;
      .mobile{
        margin-top: 5px;
        color: #666666;
        overflow: hidden;
        span{
          float: left;
          width: calc(100% / 2);
        }
      }
      .input-code{
        margin-top: 15px;
        display: flex;
        align-items: center;
        .codeText{
          display: inline-block;
          width: 60px;
          color: #333333;
          font-size: 14px;
        }
        .input{
          width: 120px;
        }
      }
    }
  }
}
/deep/ .ant-modal-header{
  display: none;
}
/deep/ .ant-modal-body{
  padding: 0;
}
/deep/ .ant-modal-footer{
  display: none;
}
</style>
